import { useEffect } from 'react';
import { Helmet } from 'react-helmet-async';

import { useUserState } from 'auth/authenticate';
import { offeredPeScheduledDate } from 'component-library/dates/dayjsApStyleAbbreviateMonths';
import LoadingSpinner from 'component-library/loading-spinner/LoadingSpinner';
import { PageTemplate } from 'component-library/page-templates/PageTemplate';
import PageTitles from 'constants/pageTitles';
import routes from 'constants/routes';
import { useAccountDetails } from 'hooks';
import { googleTagManager } from 'services';
import { useResponsiveRendering } from 'theme/breakpoints/responsiveHooks';

import gtmPeEligibleBaseEvent from './gtmPeEligibleBaseEvent';
import PaymentExtensionEligibleContent from './PaymentExtensionEligibleContent';
import { desktopHeader, mobileHeader } from './PaymentExtensionEligibleHeader';
import usePaymentExtensionEligible from './usePaymentExtensionEligibleData';

const PaymentExtensionEligiblePage = (): React.ReactElement => {
  const { getBpId } = useUserState();
  const { isAccountDetailsSuccess, accountDetails } = useAccountDetails({
    errorPagePath: routes.error.paymentExtension,
  });
  const { isPaymentOffers, isPEDataLoading, isPEDataSuccess, paymentExtensionDetails } = usePaymentExtensionEligible({
    enabled: isAccountDetailsSuccess,
  });
  const pageHeader = useResponsiveRendering(mobileHeader, desktopHeader, desktopHeader);

  useEffect((): void => {
    if (isAccountDetailsSuccess && isPEDataSuccess) {
      const { pastDueAmount } = paymentExtensionDetails;
      const formattedPeScheduledDate = offeredPeScheduledDate().format('YYYY-MM-DD');
      googleTagManager.trackViewEvent({
        ...gtmPeEligibleBaseEvent,
        actionDetail: pastDueAmount!,
        actionObject: 'past due balance',
      });
      googleTagManager.trackViewEvent({
        ...gtmPeEligibleBaseEvent,
        actionDetail: formattedPeScheduledDate,
        actionObject: 'pe date',
      });
    }
  }, [isAccountDetailsSuccess, isPEDataSuccess]);

  return (
    <>
      <Helmet>
        <title>{PageTitles.PAYMENT_EXTENSION_ELIGIBLE}</title>
      </Helmet>
      <PageTemplate
        allViewportPaddingTop="16px"
        dataTestId="payment-extension-eligible-page"
        header={pageHeader}
        tabletDesktopContentWidth="740px"
      >
        <>
          {isAccountDetailsSuccess && isPEDataSuccess && (
            <PaymentExtensionEligibleContent
              accountDetails={accountDetails}
              bpId={getBpId().toString()}
              isPaymentOffers={isPaymentOffers || false}
              paymentExtensionDetails={paymentExtensionDetails}
            />
          )}
          {isPEDataLoading && <LoadingSpinner />}
        </>
      </PageTemplate>
    </>
  );
};

export default PaymentExtensionEligiblePage;
