import { Box, SxProps, Typography } from '@mui/material';

import { theme } from 'theme/theme';

import messages from './messages';

const sx: Record<string, SxProps> = {
  circleBlue: {
    backgroundColor: theme.palette.dteBlue.dark,
    borderRadius: '50%',
    display: 'inline-block',
    height: '1em',
    margin: '0 8px',
    width: '1em',
  },
  circleGray: {
    backgroundColor: theme.palette.dteGrey.main,
    borderRadius: '50%',
    display: 'inline-block',
    height: '1em',
    margin: '0 8px',
    width: '1em',
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    gap: '10px',
  },
  numberText: {
    color: theme.palette.secondary.main,
    fontSize: '20px',
    fontWeight: '700',
    lineHeight: '20px',
  },
  rightAlignText: {
    fontSize: '14px',
    fontWeight: '400',
    lineHeight: '19.07px',
    textAlign: 'right',
  },
};

interface IPaymentsProgressLabelProps {
  numberOfInstallmentsOpted: number;
  numberOfInstallmentsRemaining: number;
}

const PaymentsProgressLabel: React.FC<IPaymentsProgressLabelProps> = (
  props: IPaymentsProgressLabelProps,
): React.ReactElement => {
  const { numberOfInstallmentsOpted, numberOfInstallmentsRemaining } = props;

  return (
    <Box sx={sx.container}>
      <Typography sx={sx.rightAlignText}>
        {messages.remainingText}
        <Box component="span" sx={sx.circleGray} />
        <Box component="span" sx={sx.numberText}>
          {numberOfInstallmentsRemaining}
        </Box>
      </Typography>
      <Typography sx={sx.rightAlignText}>
        {messages.totalPaymentsText}
        <Box component="span" sx={sx.circleBlue} />
        <Box component="span" sx={sx.numberText}>
          {numberOfInstallmentsOpted}
        </Box>
      </Typography>
    </Box>
  );
};

export default PaymentsProgressLabel;
