import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, SxProps, useTheme } from '@mui/material';
import dayjs from 'dayjs';

import { PaymentServiceInterruptionBanner } from 'component-library/banners/PaymentServiceInterruptionBanner';
import { DtePrimaryButton } from 'component-library/buttons/DtePrimaryButton';
import ViewCurrentBillButton from 'component-library/buttons/ViewCurrentBillButton';
import { offeredPeScheduledDate } from 'component-library/dates/dayjsApStyleAbbreviateMonths';
import { PaymentExtensionConfirmDetailsTitle } from 'component-library/header/ServiceTitle';
import { googleTagManager } from 'services';
import { useResponsiveSx } from 'theme/breakpoints/responsiveHooks';

import PaymentExtensionAccountInformation from '../components/PaymentExtensionAccountInformation';
import { PaymentExtensionDate } from '../components/PaymentExtensionDate';

import gtmConfirmPeDetailsBaseEvent from './gtmConfirmPeDetailsBaseEvent';
import messages from './messages';
import onCurrentBillButtonClick from './onCurrentBillButtonClick';
import onSchedulePaymentExtensionButtonClick from './onSchedulePaymentExtensionButtonClick';

interface IPEConfirmDetailsContentProps {
  accountDetails: IAccountDetailsResponse;
  paymentExtensionDetails?: IPaymentExtensionDetails;
}

const PEConfirmDetailsContent = (props: IPEConfirmDetailsContentProps): React.ReactElement => {
  const { accountDetails, paymentExtensionDetails } = props;
  const navigate = useNavigate();

  const accountNumber = Number(accountDetails?.accounts[0].accountNumber);
  const shutoffDate = dayjs(paymentExtensionDetails?.shutOffDate).format('dddd, MMM D, YYYY');

  useEffect(() => {
    googleTagManager.trackViewEvent({
      ...gtmConfirmPeDetailsBaseEvent,
      actionObject: 'confirm payment extension details',
      actionDetail: 'confirm',
    });
  }, []);

  const theme = useTheme();
  const sx: Record<string, SxProps> = {
    rootWrapper: {
      display: 'flex',
      justifyContent: 'center',
    },
    peAccountInfoAndPastDueBalanceAdapter: {
      border: `1px solid ${theme.palette.dteGrey.main}`,
      display: 'flex',
      margin: '40px 0px 32px 0px',
    },
    peAccountInfoAndPastDueBalanceAdapterMobile: {
      flexDirection: 'column',
    },
    peAccountInfoAndPastDueBalanceAdapterTabletAndDesktop: {
      flexDirection: 'row',
    },
    peDate: {
      display: 'flex',
      minWidth: '50%',
      padding: '30px',
      alignItems: 'center',
      justifyContent: 'center',
      backgroundColor: theme.palette.dteGrey.light,
    },
  };

  const peAccountInfoAndPastDueBalanceMobileSx = {
    ...sx.peAccountInfoAndPastDueBalanceAdapter,
    ...sx.peAccountInfoAndPastDueBalanceAdapterMobile,
  };
  const peAccountInfoAndPastDueBalanceTabletAndDesktopSx = {
    ...sx.peAccountInfoAndPastDueBalanceAdapter,
    ...sx.peAccountInfoAndPastDueBalanceAdapterTabletAndDesktop,
  };

  const peAccountInfoAndPastDueBalanceAdapterResponsiveSx = useResponsiveSx(
    peAccountInfoAndPastDueBalanceMobileSx,
    peAccountInfoAndPastDueBalanceTabletAndDesktopSx,
    peAccountInfoAndPastDueBalanceTabletAndDesktopSx,
  );

  return (
    <>
      <ViewCurrentBillButton onClick={onCurrentBillButtonClick} />
      <PaymentExtensionConfirmDetailsTitle shutoffDate={shutoffDate} />
      <Box data-testid="account-info-past-due-balance-adapter" sx={peAccountInfoAndPastDueBalanceAdapterResponsiveSx}>
        <PaymentExtensionAccountInformation accountInfo={accountDetails} accountNumber={accountNumber} />
        <Box sx={sx.peDate}>
          <PaymentExtensionDate date={offeredPeScheduledDate()} fontVariant="body2" />
        </Box>
      </Box>
      <Box sx={sx.rootWrapper}>
        <DtePrimaryButton
          name={messages.schedulePaymentExtension}
          onClick={(): void => onSchedulePaymentExtensionButtonClick(navigate)}
        />
      </Box>
      <PaymentServiceInterruptionBanner />
    </>
  );
};

export default PEConfirmDetailsContent;
