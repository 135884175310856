import { useNavigate } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import { AxiosResponse } from 'axios';

import { paymentExtensionDetailsApi } from 'api/payment-extension-details-api/payment-extension-details-api';
import refactoredEligibilityApi from 'api/refactored-eligibility-api/refactor-eligibility-api';
import routes from 'constants/routes';
import { logger } from 'services';

interface IUsePaymentExtensionEligibleParams {
  enabled: boolean;
}

interface IUsePaymentExtensionEligibleData {
  bpId?: string;
  isFetched?: boolean;
  isPaymentOffers?: boolean;
  isPEDataError?: boolean;
  isPEDataLoading?: boolean;
  isPEDataSuccess?: boolean;
  paymentExtensionDetails: IPaymentExtensionDetails;
}

const usePaymentExtensionEligible = (params: IUsePaymentExtensionEligibleParams): IUsePaymentExtensionEligibleData => {
  const { enabled } = params;
  const navigate = useNavigate();
  const {
    isFetched,
    isError: isPaymentExtensionDetailsError,
    isLoading: isPaymentExtensionDetailsLoading,
    isSuccess: isPaymentExtensionDetailsSuccess,
    data: paymentExtensionDetailsResponse,
  } = useQuery({
    enabled,
    onError: (error: unknown) => {
      logger.info('[API Error] PE Details API Call Error! Navigating to PE Error Page', error);
      navigate(routes.error.paymentExtension);
    },
    onSuccess: (httpResponse: AxiosResponse<IPaymentExtensionDetailsResponse>): void => {
      const { paymentExtensionDetails } = httpResponse.data;

      logger.info('[API Success] PE Details API Call Success!', httpResponse);

      if (!paymentExtensionDetails) {
        logger.info('[API Success - No Data] PE Details API Response is Empty! Navigating to PE Error Page');
        navigate(routes.error.paymentExtension);
      }

      logger.info('payment extension details api response : ', paymentExtensionDetails);

      const { eligibilityFlag, sumOfAllPayments, pastDueAmount, daysLeftInLock } = paymentExtensionDetails;

      const eligibleForPE = eligibilityFlag === 'Y';
      const scheduledForPe = eligibilityFlag === 'S';

      if (
        sumOfAllPayments &&
        pastDueAmount &&
        eligibleForPE &&
        parseFloat(sumOfAllPayments) >= parseFloat(pastDueAmount) &&
        daysLeftInLock === undefined
      ) {
        navigate(routes.paymentExtension.confirmationDetails);
      }

      if (scheduledForPe) {
        navigate(routes.paymentExtension.scheduled);
      }

      if (!eligibleForPE && !scheduledForPe) {
        logger.info('[API Success] Ineligible for PE! Navigating to PE Error Page');
        navigate(routes.error.paymentExtension);
      }
    },
    queryKey: ['payment-extension-details'],
    queryFn: paymentExtensionDetailsApi,
    retry: 0,
    refetchOnWindowFocus: false,
  });

  const {
    data: refactoredEligibilityResponse,
    isError: isRefactoredEligibilityError,
    isLoading: isRefactoredEligibilityLoading,
  } = useQuery({
    enabled: isPaymentExtensionDetailsSuccess,
    queryKey: ['refactored-eligibility-payment-extension-check'],
    queryFn: refactoredEligibilityApi,
    retry: 0,
    refetchOnWindowFocus: false,
    onSuccess: (httpResponse: AxiosResponse<IRefactoredEligibilityResponse>) => {
      const { messageReply } = httpResponse.data;
      if (messageReply.replyCode.toLowerCase() !== 'success') {
        navigate(routes.error.paymentExtension);
      }
    },
    onError: (error: unknown) => {
      logger.info('[API Error] Refactored Eligibility Call Error! Navigating to PE Error Page', error);
      navigate(routes.error.paymentExtension);
    },
  });

  if (
    paymentExtensionDetailsResponse?.data.paymentExtensionDetails &&
    refactoredEligibilityResponse?.data.eligible.eligibilityType
  ) {
    const isPaymentOffers = refactoredEligibilityResponse?.data.eligible.eligibilityType === 'PaymentOffers';
    return {
      isFetched,
      isPaymentOffers,
      isPEDataError: false,
      isPEDataLoading: false,
      isPEDataSuccess: true,
      paymentExtensionDetails: paymentExtensionDetailsResponse.data.paymentExtensionDetails,
    };
  }

  return {
    isPaymentOffers: false,
    isFetched,
    isPEDataError: isPaymentExtensionDetailsError || isRefactoredEligibilityError,
    isPEDataLoading: isPaymentExtensionDetailsLoading || isRefactoredEligibilityLoading,
    isPEDataSuccess: false,
    paymentExtensionDetails: {
      eligibilityFlag: 'NA',
      pastDueAmount: '0',
      shutOffDate: '',
    },
  };
};

export default usePaymentExtensionEligible;
