import { useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import { Box, SxProps, Typography, useTheme } from '@mui/material';

import { useUserState } from 'auth/authenticate';
import { DtePrimaryButton } from 'component-library/buttons/DtePrimaryButton';
import { DteSecondaryButton } from 'component-library/buttons/DteSecondaryButton';
import HeaderDesktop from 'component-library/header/HeaderDesktop';
import HeaderMobile from 'component-library/header/HeaderMobile';
import { DteTextLink } from 'component-library/links/DteTextLink';
import { PageTemplate } from 'component-library/page-templates/PageTemplate';
import { urls } from 'constants/';
import {
  peErrorContactUsClickEvent,
  peErrorPhoneClickEvent,
  peErrorViewCurrentBillClickEvent,
  peErrorViewEvent,
} from 'gtm/gtmEventsPaymentExtension';
import { useGoogleTagManager } from 'gtm/useGoogleTagManager';
import { googleTagManager } from 'services';
import { useResponsiveRendering } from 'theme/breakpoints/responsiveHooks';
import { currentBillUrl } from 'urls-and-paths';

const customerContactNumber = '(800) 477-4747';

const PaymentExtensionErrorContent = (): React.ReactElement => {
  const { isAuthenticated } = useUserState();
  const theme = useTheme();
  const sx: Record<string, SxProps> = {
    contentWrapper: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      margin: '40px 0px',
      gap: '32px',
    },
    title: {
      color: theme.palette.primary.main,
    },
    subtitle: {
      color: theme.palette.secondary.main,
    },
    buttonWrapper: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      gap: '16px',
      width: '100%',
    },
    wordWrap: {
      overflowWrap: 'break-word',
      whiteSpace: 'nowrap',
    },
  };

  const paymentExtensionCopyA = `We are unable to complete your Payment Extension at this time. Please contact us at `;
  const paymentExtensionCopyB = `. We look forward to assisting you.`;

  const contactUsHandler = (): void => {
    googleTagManager.trackButtonClick({
      ...peErrorContactUsClickEvent,
      authenticationStatus: isAuthenticated,
    });
    window.location.href = urls.contactUsLink;
  };

  const phoneNumberHandler = (): void => {
    googleTagManager.trackButtonClick({
      ...peErrorPhoneClickEvent,
      authenticationStatus: isAuthenticated,
    });
  };

  const redirectToCurrentBillHandler = (): void => {
    googleTagManager.trackButtonClick({
      ...peErrorViewCurrentBillClickEvent,
      authenticationStatus: isAuthenticated,
    });
    window.location.href = currentBillUrl;
  };

  return (
    <Box sx={sx.contentWrapper}>
      <Typography variant="title" sx={sx.title} role="heading" aria-level={1}>
        Contact Us
      </Typography>
      <Box sx={sx.subtitle}>
        <Typography variant="body1">
          {paymentExtensionCopyA}
          <Box component="span" sx={sx.wordWrap}>
            <DteTextLink
              href={`tel:${customerContactNumber}`}
              name={customerContactNumber}
              variant="body2"
              onClick={phoneNumberHandler}
            />
          </Box>
          {paymentExtensionCopyB}
        </Typography>
      </Box>
      <Box sx={sx.buttonWrapper}>
        <DtePrimaryButton name="Contact Us" href={`tel:${customerContactNumber}`} onClick={contactUsHandler} />
        <DteSecondaryButton name="View Current Bill" onClick={redirectToCurrentBillHandler} />
      </Box>
    </Box>
  );
};

export const PaymentExtensionErrorPage = (): React.ReactElement => {
  const { isAuthenticated } = useUserState();
  const isGoogleTagManagerLoaded = useGoogleTagManager();
  const headerTitle = 'Payment Extension';
  const mobileHeader = (
    <HeaderMobile titleText={headerTitle} isHeaderButtonRendered={false} isInteractiveDteLogo={false} />
  );
  const desktopHeader = (
    <HeaderDesktop titleText={headerTitle} isHeaderButtonRendered={false} isInteractiveDteLogo={false} />
  );
  const pageHeader = useResponsiveRendering(mobileHeader, desktopHeader, desktopHeader);

  useEffect(() => {
    if (isGoogleTagManagerLoaded) {
      googleTagManager.trackViewEvent({
        ...peErrorViewEvent,
        authenticationStatus: isAuthenticated,
      });
    }
  }, [isGoogleTagManagerLoaded]);

  const content = <PaymentExtensionErrorContent />;

  return (
    <>
      <Helmet>
        <title>Error | DTE Energy</title>
      </Helmet>
      <PageTemplate
        header={pageHeader}
        allViewportPaddingTop="0px"
        tabletDesktopContentWidth="470px"
        dataTestId="pe-error-page-container"
      >
        {content}
      </PageTemplate>
    </>
  );
};
