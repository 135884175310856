import { useNavigate } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import { AxiosResponse } from 'axios';

import { paymentExtensionDetailsApi } from 'api/payment-extension-details-api/payment-extension-details-api';
import routes from 'constants/routes';
import { logger } from 'services';

interface IPaymentExtensionDetailsParams {
  enabled: boolean;
}

interface IUsePaymentExtensionDetailsData {
  bpId?: string;
  isFetched?: boolean;
  isPaymentExtensionDetailsError?: boolean;
  isPaymentExtensionDetailsLoading?: boolean;
  isPaymentExtensionDetailsSuccess?: boolean;
  paymentExtensionDetails?: IPaymentExtensionDetails;
  refetchPaymentExtensionDetails?: () => void;
}

const usePaymentExtensionDetails = (params: IPaymentExtensionDetailsParams): IUsePaymentExtensionDetailsData => {
  const { enabled } = params;
  const navigate = useNavigate();
  const {
    isFetched,
    isLoading: isPaymentExtensionDetailsLoading,
    isSuccess: isPaymentExtensionDetailsSuccess,
    data: paymentExtensionDetailsResponse,
    refetch: refetchPaymentExtensionDetails,
  } = useQuery({
    enabled,
    onError: (error: unknown) => {
      logger.info('[API Error] PE Details API Call Error! Navigating to PE Error Page', error);
      navigate(routes.error.paymentExtension);
    },
    onSuccess: (httpResponse: AxiosResponse<IPaymentExtensionDetailsResponse>): void => {
      const { paymentExtensionDetails } = httpResponse.data;

      logger.info('[API Success] PE Details API Call Success!', httpResponse);

      if (!paymentExtensionDetails) {
        logger.info('[API Success - No Data] PE Details API Response is Empty! Navigating to PE Error Page');
        navigate(routes.error.paymentExtension);
      }

      logger.info('payment extension details api response : ', paymentExtensionDetails);

      const { eligibilityFlag, sumOfAllPayments, pastDueAmount, daysLeftInLock } = paymentExtensionDetails;

      const eligibleForPE = eligibilityFlag === 'Y';
      const scheduledForPe = eligibilityFlag === 'S';

      const isInterrupted =
        sumOfAllPayments &&
        pastDueAmount &&
        eligibleForPE &&
        parseFloat(sumOfAllPayments) >= parseFloat(pastDueAmount) &&
        daysLeftInLock === undefined;

      if (isInterrupted) {
        logger.info('[API Success] Interrupted Flow! Navigating to PE Confirmation Details Page');
        navigate(routes.paymentExtension.confirmationDetails);
      }

      if (scheduledForPe) {
        logger.info('[API Success] Scheduled for PE! Navigating to PE Scheduled Page');
        navigate(routes.paymentExtension.scheduled);
      }

      if (!eligibleForPE && !scheduledForPe) {
        logger.info('[API Success] Ineligible for PE! Navigating to PE Error Page');
        navigate(routes.error.paymentExtension);
      }
    },
    queryKey: ['payment-extension-details'],
    queryFn: paymentExtensionDetailsApi,
    retry: 0,
    refetchOnWindowFocus: false,
  });

  if (isPaymentExtensionDetailsSuccess && paymentExtensionDetailsResponse?.data.paymentExtensionDetails) {
    return {
      isFetched,
      isPaymentExtensionDetailsError: false,
      isPaymentExtensionDetailsLoading: false,
      isPaymentExtensionDetailsSuccess: true,
      paymentExtensionDetails: paymentExtensionDetailsResponse.data.paymentExtensionDetails,
      refetchPaymentExtensionDetails,
    };
  }

  return {
    isFetched,
    isPaymentExtensionDetailsSuccess: false,
    isPaymentExtensionDetailsLoading,
    paymentExtensionDetails: {
      eligibilityFlag: 'NA',
      pastDueAmount: '0',
      shutOffDate: '',
    },
    refetchPaymentExtensionDetails,
  };
};

export default usePaymentExtensionDetails;
