import { googleTagManager } from 'services';

import gtmPeScheduledBaseEvent from './gtmPeScheduledBaseEvent';

const onViewScheduledPaymentClick = (): void => {
  googleTagManager.trackButtonClick({
    ...gtmPeScheduledBaseEvent,
    actionDetail: 'scheduled',
    actionObject: 'view scheduled payment',
  });
};

export default onViewScheduledPaymentClick;
