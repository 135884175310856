import { MutationFunction } from '@tanstack/react-query';

import { offeredPeScheduledDate } from 'component-library/dates/dayjsApStyleAbbreviateMonths';
import routes from 'constants/routes';
import { urls } from 'constants/urls';
import { googleTagManager, logger } from 'services';

import gtmPeEligibleBaseEvent from './gtmPeEligibleBaseEvent';

const onScheduledAPaymentClick = (
  mutateLambda: MutationFunction,
  accountNumber?: string,
  bpId?: string,
  paymentExtensionDetails?: IPaymentExtensionDetails,
): void => {
  googleTagManager.trackButtonClick({
    ...gtmPeEligibleBaseEvent,
    actionDetail: 'eligible',
    actionObject: 'schedule payment button',
  });

  if (paymentExtensionDetails?.pastDueAmount && accountNumber && bpId) {
    const { pastDueAmount } = paymentExtensionDetails;
    const formData = new URLSearchParams();

    formData.append('accounts', accountNumber);
    formData.append('bpId', `${bpId}`);
    formData.append('pastDueAmount', pastDueAmount);
    formData.append('peEligibleUrl', window.location.hostname + window.location.pathname);
    formData.append('peScheduledDate', offeredPeScheduledDate().format('YYYY-MM-DD'));
    formData.append('peScheduledUrl', window.location.hostname + routes.paymentExtension.scheduled);
    logger.info('formData', formData);
    mutateLambda({
      url: urls.lambdaPaymentExtensionUrl,
      formData,
    });
  } else {
    logger.info('Error - can not find eligible data to pass to Payment Center');
  }
};
export default onScheduledAPaymentClick;
