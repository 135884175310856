import logger from '../logger';

import getAuthenticationStatus from './getAuthenticationStatus';
import pushDataLayer from './pushDataLayer';

type GtmTrackAccordionClickProps = GtmEventProps;

const trackAccordionClick = (params: GtmTrackAccordionClickProps, isExpanded: boolean): void => {
  const { actionObject, authenticationStatus, transactionStepName, transactionType, transactionStepNumber, userType } =
    params;
  if (actionObject) {
    const authStatus = getAuthenticationStatus(authenticationStatus);

    const event: IGtmEventProps = {
      action_detail: isExpanded ? 'hide' : 'unhide',
      action_object: actionObject,
      action_type: 'click',
      authentication_status: authStatus,
      event: 'transaction_event',
      transaction_name: 'collections',
      transaction_step_name: transactionStepName,
      transaction_step_number: transactionStepNumber,
      transaction_type: transactionType,
    };
    if (userType) {
      event.user_type = userType;
    }
    logger.info(`[Google Tag Manager] Sending tags for accordion clicks`, event);

    pushDataLayer(event);
  }
};

export default trackAccordionClick;
