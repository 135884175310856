import { useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import { useNavigate } from 'react-router-dom';

import LoadingSpinner from 'component-library/loading-spinner/LoadingSpinner';
import { PageTemplate } from 'component-library/page-templates/PageTemplate';
import PageTitles from 'constants/pageTitles';
import routes from 'constants/routes';
import { useAccountDetails, usePaymentExtensionDetails, usePELock } from 'hooks';
import isUserReadyForPELock from 'payment-extension/isUserReadyForPELock';
import { googleTagManager } from 'services';
import { useResponsiveRendering } from 'theme/breakpoints/responsiveHooks';

import gtmPeScheduledBaseEvent from './gtmPeScheduledBaseEvent';
import PaymentExtensionScheduledContent from './PaymentExtensionScheduledContent';
import { desktopHeader, mobileHeader } from './PaymentExtensionScheduleHeader';

const PaymentExtensionScheduledPage = (): React.ReactElement => {
  const navigate = useNavigate();
  const { isAccountDetailsSuccess, accountDetails } = useAccountDetails({
    errorPagePath: routes.error.paymentExtension,
  });
  const {
    isPaymentExtensionDetailsLoading,
    isPaymentExtensionDetailsSuccess,
    paymentExtensionDetails,
    refetchPaymentExtensionDetails,
  } = usePaymentExtensionDetails({
    enabled: isAccountDetailsSuccess,
  });
  const { isMutatePELockSuccess, mutatePELock } = usePELock();
  const pageHeader = useResponsiveRendering(mobileHeader, desktopHeader, desktopHeader);

  useEffect((): void => {
    if (isPaymentExtensionDetailsSuccess && paymentExtensionDetails) {
      googleTagManager.trackViewEvent({
        ...gtmPeScheduledBaseEvent,
        actionObject: 'payment extension details',
        actionDetail: 'scheduled',
      });
    }
  }, []);

  useEffect((): void => {
    if (isPaymentExtensionDetailsSuccess && paymentExtensionDetails) {
      if (
        isUserReadyForPELock({ eligibilityFlag: paymentExtensionDetails.eligibilityFlag, paymentExtensionDetails }) &&
        accountDetails.accounts[0].accountNumber
      ) {
        const lockRequest = {
          contractAccountNumber: Number(accountDetails.accounts[0].accountNumber),
        };
        mutatePELock(lockRequest);
      } else if (paymentExtensionDetails.eligibilityFlag !== 'S') {
        navigate(routes.paymentExtension.eligible);
      }
    }
  }, [paymentExtensionDetails, accountDetails]);

  useEffect((): void => {
    if (isMutatePELockSuccess && refetchPaymentExtensionDetails) {
      refetchPaymentExtensionDetails();
    }
  }, [isMutatePELockSuccess, refetchPaymentExtensionDetails]);

  const isApiCallSuccess = isAccountDetailsSuccess && isPaymentExtensionDetailsSuccess;

  return (
    <>
      <Helmet>
        <title>{PageTitles.PAYMENT_EXTENSION}</title>
      </Helmet>
      <PageTemplate
        header={pageHeader}
        allViewportPaddingTop="0px"
        tabletDesktopContentWidth="740px"
        dataTestId="payment-extension-eligible-page"
      >
        <>
          {isApiCallSuccess && paymentExtensionDetails?.eligibilityFlag === 'S' && (
            <PaymentExtensionScheduledContent paymentExtensionDetails={paymentExtensionDetails} />
          )}
          {isPaymentExtensionDetailsLoading && <LoadingSpinner />}
        </>
      </PageTemplate>
    </>
  );
};

export default PaymentExtensionScheduledPage;
